import { makeAutoObservable } from "mobx"

export class SongStore {
  autor = null
  chwyty = null
  data = null
  id = null
  nagranie = null
  namiar = null
  nuty = null
  opis = null
  prezentacja = null
  status = null
  tekst = null
  temat = null
  tempo = null
  tonacja = null
  tytul = null

  transposition = 0

  constructor(songObj) {
    this.autor = songObj.autor
    this.chwyty = songObj.chwyty
    this.data = songObj.data
    this.id = songObj.id
    this.nagranie = songObj.nagranie
    this.namiar = songObj.namiar
    this.nuty = songObj.nuty
    this.opis = songObj.opis
    this.prezentacja = songObj.prezentacja
    this.status = songObj.status
    this.tekst = songObj.tekst
    this.temat = songObj.temat
    this.tempo = songObj.tempo
    this.tonacja = songObj.tonacja
    this.tytul = songObj.tytul

    makeAutoObservable(this)
  }

  incrementTransposition = () => {
    this.transposition += 1
    // if (this.transposition >= 12) {
    //   this.transposition = 0
    // }
  }

  decrementTransposition = () => {
    this.transposition -= 1
    // if (this.transposition <= -12) {
    //   this.transposition = 0
    // }
  }

  resetTransposition = () => {
    this.transposition = 0
  }
}

import * as React from 'react';
import { SafeAreaView, ScrollView } from 'react-native';
import { Text, List, Divider } from 'react-native-paper';
import { observer } from "mobx-react-lite"
import * as Linking from 'expo-linking';
import { useNavigation } from '@react-navigation/native';

import { useStores } from '../../src/@stores/useStores';
import { screenStyle } from '../../src/@styles/screenStyle';
import { headingStyle } from '../../src/@styles/headingStyle';

export const SettingsScreen = observer(() => {
  const navigation = useNavigation();
  const { progressDialogStore, comingSoonDialogStore, songBottomBarStore, songsStore } = useStores();

  const reloadSongs = async () => {
    try {
      progressDialogStore.show('Pobieranie', 'Pobieranie piosenek...')
      await songsStore.reloadSongs()
    } finally {
      progressDialogStore.hide()
    }
  }

  const reportBug = () => Linking.openURL('https://wa.me/48726071091')

  const fontSize = () => {
    navigation.navigate('Song', { id: songsStore.activeSong.id });
    songBottomBarStore.showFontSize()
  }

  const theme = () => {
    comingSoonDialogStore.show()
  }

  return (
    <SafeAreaView style={screenStyle}>
      <ScrollView>
        <Text style={headingStyle} variant="displayMedium">
          Ustawienia
        </Text>
        <List.Section>
          <List.Item
            title="Aktualizuj piosenki"
            left={(props) => <List.Icon {...props} icon="reload" />}
            onPress={reloadSongs}
          />
          <Divider />
          <List.Item
            title="Rozmiar czcionki"
            left={(props) => <List.Icon {...props} icon="format-size" />}
            onPress={fontSize}
          />
          <Divider />
          <List.Item
            title="Motyw"
            left={(props) => <List.Icon {...props} icon="theme-light-dark" />}
            onPress={theme}
          />
          <Divider />
          <List.Item
            title="Zgłoś błąd"
            left={(props) => <List.Icon {...props} icon="bug" />}
            onPress={reportBug}
          />
        </List.Section>
      </ScrollView>
    </SafeAreaView>
  )
})

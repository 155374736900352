import * as React from 'react';
import { SafeAreaView, ScrollView } from 'react-native';
import { observer } from "mobx-react-lite"
import { Text } from 'react-native-paper';
import _ from 'lodash';

import { useStores } from '../../src/@stores/useStores';
import { screenStyle } from '../../src/@styles/screenStyle';
import { SongLine } from '../../src/@components/SongLine';
import { SongFab } from '../../src/@components/SongFab';
import { SongBottomBar } from '../../src/@components/SongBottomBar';
import { checkPwa } from '../../src/@utils/checkPwa';
import { InstallInstructions } from '../../src/@screens/InstallInstructions';
import { ListenerFab } from '../../src/@components/ListenerFab';
import { BackButton } from '../../src/@components/BackButton';

export const SongScreen = observer(({ route }) => {
  const { fontSizeStore, songsStore, songBottomBarStore } = useStores();

  if (!checkPwa()) { return <InstallInstructions /> }

  // const { id } = route.params;
  let { tempo, tytul, transposition } = songsStore.activeSong;
  tempo = (tempo || '').trim();
  tytul = (tytul || '').trim();

  const red = { color: 'red' }
  const titleFontSize = (18 + fontSizeStore.modifier) * 1.33;

  const onSongTouch = () => {
    songBottomBarStore.hideBar()
  }

  const transpositionInfo = transposition === 0 ? null : (<Text>Zmiana tonacji: {transposition / 2}</Text>);

  return (
    <SafeAreaView style={screenStyle}>
      <ScrollView style={{ padding: 16 }} onTouchStart={onSongTouch} onClick={onSongTouch}>
        <Text style={{fontSize: titleFontSize}}>
          { tempo.length > 0 &&
            <Text>
              <Text>[</Text>
              <Text style={red}>{tempo}</Text>
              <Text>] </Text>
            </Text>
          }
          <Text>{tytul}</Text>
        </Text>
        { transpositionInfo }
        <SongLine key={-1} line={{}} />
        { songsStore.activeSongLines.map((line) =>
          <SongLine key={line.idx} line={line} />
        )}
      </ScrollView>
      <SongBottomBar />
      <ListenerFab />
      <BackButton />
      <SongFab />
    </SafeAreaView>
  );
})

import { observer } from "mobx-react-lite"
import { Text } from 'react-native-paper';

import { useStores } from '../../src/@stores/useStores';
import { Chord } from '../../src/@components/Chord';
import { parseChords, fakeParseChords } from '../../src/@utils/parseChords';

export const SongLine = observer((props) => {
  const { fontSizeStore, songsStore } = useStores();
  const { transposition } = songsStore.activeSong;

  const { line } = props;
  const { words, chords } = line;
  const chordObjects = transposition !== 0 ? parseChords(chords) : fakeParseChords(chords);

  const fontSize = 18 + fontSizeStore.modifier;

  return (
    <Text style={{fontSize: fontSize}} key={line.line}>
      <Text>{words} </Text>
      { chordObjects.map((chord, idx) => <Chord key={idx} chord={chord} />) }
    </Text>
  );
})

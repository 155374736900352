import { chordRegex } from './parseChords';

const major = ["C", "Cis", "D", "Dis", "E", "F", "Fis", "G", "Gis", "A", "B", "H"]
const minor = ["c", "cis", "d", "dis", "e", "f", "fis", "g", "gis", "a", "b", "h"]

const aliases = {
  "C#": "Cis",
  "Db": "Cis",
  "D#": "Dis",
  "Eb": "Dis",
  "F#": "Fis",
  "Gb": "Fis",
  "G#": "Gis",
  "Ab": "Gis",
  "Bb": "B",

  "C#m": "cis",
  "D#m": "dis",
  "F#m": "fis",
  "G#m": "gis",

  "Cm": "c",
  "Dm": "d",
  "Em": "e",
  "Fm": "f",
  "Gm": "g",
  "Am": "a",
  "Bm": "b",
  "Hm": "h",
};

const normalizeChord = (chord) => {
  // chord is split into 5 parts using regex
  // first 3 parts are the base chord, e.g. C, F#m, etc.
  // last 2 parts are the extension, e.g. sus7
  const [_, p1, p2, p3, p4, p5] = chordRegex.exec(chord)

  const base = [p1, p2, p3].join('')
  const ext = [p4, p5].join('')


  return [aliases[base] || base, ext]
}

const findPosition = (base) => {
  const majorIdx = major.indexOf(base)

  if (majorIdx > -1) {
    return [major, majorIdx]
  } else {
    const minorIdx = minor.indexOf(base)
    return [minor, minorIdx]
  }
}

export const transpose = (chord, tones) => {
  const [base, ext] = normalizeChord(chord)

  // Parsing error
  if (base === '') { return '???' }

  const [keys, idx] = findPosition(base)

  // Parsing error
  if (idx === -1) { return '???' }

  const length = keys.length
  const newIdx = (((idx + tones) % length) + length) % length;

  return keys[newIdx] + ext
}

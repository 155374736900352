export const chordRegex = /\b([cdefgabhCDEFGABH])(is|#|b)?(m)?(M|sus|aug|dim|add|addM)?(\d)?((?<=#)|\b)/;

// Parse string of chords into arrach of chord and non-chord objects.
// example: parseChords("G (fis/G) D7 /x2")
// => [
//   {val: "G", isChord: true},
//   {val: " (", isChord: false},
//   {val: "fis", isChord: true},
//   {val: "/", isChord: false},
//   {val: "G", isChord: true},
//   {val: ") ", isChord: false},
//   {val: "D7", isChord: true},
//   {val: " /x2", isChord: false},
//   ]
export const parseChords = (chords) => {
  if (!chords) return [];

  const result = [];
  let currentIndex = 0;

  while (currentIndex < chords.length) {
    // Find next chord match
    const remainingString = chords.slice(currentIndex);
    const match = remainingString.match(chordRegex);

    if (match && match.index === 0) {
      // Found chord at current position
      result.push({
        val: match[0],
        isChord: true
      });
      currentIndex += match[0].length;
    } else {
      // Find next chord or end of string
      const nextMatch = remainingString.match(chordRegex);
      const textLength = nextMatch ? nextMatch.index : remainingString.length;

      // Add non-chord text
      result.push({
        val: remainingString.slice(0, textLength),
        isChord: false
      });
      currentIndex += textLength;
    }
  }

  return result;
};

// Return entire string as a chord object.
export const fakeParseChords = (chords) => [{val: chords, isChord: true}];

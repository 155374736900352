import { makeAutoObservable } from "mobx"
import _ from 'lodash';
import normalizeStrings from "normalize-strings";

import * as SongsApi from '../../src/@services/api/SongsApi';
import * as SongsStorage from '../../src/@services/storage/SongsStorage';
import { parseSong } from '../../src/@utils/parseSong';
import { SongStore } from '../../src/@stores/SongStore';

export class SongsStore {
  songs = []
  favoriteIds = []
  playlistIds = []

  activeSong = new SongStore({})

  searchQuery = ""
  searchQueryActive = ""

  constructor() {
    makeAutoObservable(this)
    this.loadSongs()
  }

  // Sort skipping the part in brackets
  sortSongs = (songs) => _.sortBy(songs, (s) => s.tytul.replace(/\([\w\s]*\)\s/i, ''))

  loadSongs = async () => {
    await this.getCachedSongs();
    if (this.songs.length === 0) { await this.reloadSongs() };
    this.favoriteIds = await SongsStorage.getFavorites();
    this.playlistIds = await SongsStorage.getPlaylist();
    this.setActive(this.songs[0]);
  }

  getCachedSongs = async () => {
    const fetchedSongs = await SongsStorage.getSongs();
    const sortedSongs = this.sortSongs(fetchedSongs || []);
    this.setSongs(sortedSongs);
  }

  reloadSongs = async () => {
    const fetchedSongs = await SongsApi.getSongs();
    await SongsStorage.setSongs(fetchedSongs);
    const sortedSongs = this.sortSongs(fetchedSongs);
    this.setSongs(sortedSongs);
  }

  setSongs = (rawSongs) => {
    this.songs = rawSongs.map((s) => new SongStore(s));
  }

  setActive = (song) => {
    this.activeSong = song;
  }

  get activeSongLines() {
    return this.activeSong.tekst ? parseSong(this.activeSong.tekst) : [];
  }

  search = (query) => {
    this.searchQuery = query;
    this.debounceSearch();
  }

  debounceSearch = () => {
    _.debounce(() => {
      this.searchQueryActive = this.searchQuery;
    }, 1000)();
  }

  normalize = (str) => normalizeStrings(str).toLowerCase();

  get filteredSongs() {
    return this.songs.filter((s) => this.normalize(s.tekst).includes(this.normalize(this.searchQueryActive)));
  }

  addFavorite = () => {
    this.favoriteIds = _.union(this.favoriteIds, [this.activeSong.id]);
    SongsStorage.setFavorites(this.favoriteIds);
    return this.favoriteIds;
  }

  removeFavorite = () => {
    this.favoriteIds = _.difference(this.favoriteIds, [this.activeSong.id]);
    SongsStorage.setFavorites(this.favoriteIds);
    return this.favoriteIds;
  }

  get isFavorite() {
    return this.favoriteIds.includes(this.activeSong.id);
  }

  get favoriteSongs() {
    return this.songs.filter((s) => this.favoriteIds.includes(s.id));
  }

  addToPlaylist = () => {
    this.playlistIds = _.union(this.playlistIds, [this.activeSong.id]);
    SongsStorage.setPlaylist(this.playlistIds);
    return this.playlistIds;
  }

  removeFromPlaylist = () => {
    this.playlistIds = _.difference(this.playlistIds, [this.activeSong.id]);
    SongsStorage.setPlaylist(this.playlistIds);
    return this.playlistIds;
  }

  get isOnPlaylist() {
    return this.playlistIds.includes(this.activeSong.id);
  }

  get playlistSongs() {
    return this.songs.filter((s) => this.playlistIds.includes(s.id));
  }

  setFirstAsActive = () => {
    this.setActive(this.filteredSongs[0]);
  }
}

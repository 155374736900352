import { observer } from "mobx-react-lite";

import { FontSizeBar } from './FontSizeBar';
import { TranspositionBar } from './TranspositionBar';

import { useStores } from '../../src/@stores/useStores';

export const SongBottomBar = observer(() => {
  const { songBottomBarStore } = useStores();
  
  switch(songBottomBarStore.mode) {
    case 'FONT_SIZE':
      return (<FontSizeBar />);
    case 'TRANSPOSITION':
      return (<TranspositionBar />);
    default:
      return null;
  }
});

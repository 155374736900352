import { FAB } from 'react-native-paper';
import { observer } from "mobx-react-lite"
import { StyleSheet } from 'react-native';

import { useStores } from '../../src/@stores/useStores';

const styles = StyleSheet.create({
  fab: {
    position: 'fixed',
  },
});

export const SongFab = observer(() => {
  const { songFabStore, comingSoonDialogStore, songBottomBarStore, songsStore, listenerStore } = useStores();

  const favoriteAction = songsStore.isFavorite ?
    { icon: 'star-outline', label: 'Usuń z ulubionych', onPress: songsStore.removeFavorite } :
    { icon: 'star', label: 'Dodaj do ulubionych', onPress: songsStore.addFavorite }

  const playlistAction = songsStore.isOnPlaylist ?
    { icon: 'playlist-remove', label: 'Usuń z playlisty', onPress: songsStore.removeFromPlaylist } :
    { icon: 'playlist-plus', label: 'Dodaj do playlisty', onPress: songsStore.addToPlaylist }

  const listenerAction = { icon: 'wifi', label: 'Włącz nasłuchiwanie', onPress: comingSoonDialogStore.show }

  const fontSizeAction = { icon: 'format-size', label: 'Rozmiar czcionki', onPress: songBottomBarStore.toggleFontSize }

  const transposeAction = { icon: 'music', label: 'Tonacja', onPress: songBottomBarStore.toggleTransposition }

  // Disabled for now
  // const listenerAction = listenerStore.enabled ?
  //   { icon: 'wifi-off', label: 'Wyłącz nasłuchiwanie', onPress: listenerStore.toggle } :
  //   { icon: 'wifi', label: 'Włącz nasłuchiwanie', onPress: listenerStore.toggle }

  return (
    <FAB.Group
      style={styles.fab}
      open={songFabStore.open}
      icon={songFabStore.open ? 'close' : 'plus'}
      actions={[
        favoriteAction,
        playlistAction,
        transposeAction,
        fontSizeAction,
        listenerAction,
      ]}
      onStateChange={(x) => songFabStore.setOpen(x.open)}
    />
  );
})

import { makeAutoObservable } from "mobx"

export class SongBottomBarStore {
  mode = 'HIDDEN'

  constructor() {
    makeAutoObservable(this)
  }

  hideBar = () => {
    this.mode = 'HIDDEN';
  }

  showFontSize = () => {
    this.mode = 'FONT_SIZE';
  }

  toggleFontSize = () => {
    this.mode = this.mode === 'FONT_SIZE' ? 'HIDDEN' : 'FONT_SIZE';
  }

  showTransposition = () => {
    this.mode = 'TRANSPOSITION';
  }

  toggleTransposition = () => {
    this.mode = this.mode === 'TRANSPOSITION' ? 'HIDDEN' : 'TRANSPOSITION';
  }
}

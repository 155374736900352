import { observer } from "mobx-react-lite"
import { Text } from 'react-native-paper';

import { useStores } from '../../src/@stores/useStores';
import { transpose } from '../../src/@utils/transpose';

export const Chord = observer((props) => {
  const red = { color: 'red' }
  const blue = {
    color: 'blue',
    // textDecoration: 'line-through',
  }

  const { val, isChord } = props.chord;

  const { songsStore } = useStores();
  const { transposition } = songsStore.activeSong;

  const transposedChord = isChord && transposition !== 0 ? transpose(val, transposition) : val;

  return (
    <Text style={isChord ? red : blue}>{transposedChord}</Text>
  );
});
